import { Component } from "react";
import { withTranslation } from "react-i18next";
import {
	Paper,
	CircularProgress,
	Checkbox,
	Snackbar,
	Button,
	Collapse,
	ListItemButton,
	ListItemText,
	ListItemIcon,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
} from "@mui/material";
import Svg from "../svg";
// services
import User from "../../services/user";
import Gupport from "../../services/gupport";
import { sortAlphabetically } from "../../services/l10n";
import { merge } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { WithTranslation } from "react-i18next";
import type { UserId } from "../../types/user";
import type { TablePermission, CmdGetTableNames, CmdSaveGlogin } from "../../types/gupport";

type Props = Readonly<WithTranslation & {
	tableRead: ReadonlyArray<TablePermission>;
	tableWrite: ReadonlyArray<TablePermission>;
	userId: UserId;
}>;

type State = {
	loading: boolean;
	allTables: any;
	tables: any;
	buttonDisabled: boolean;
	openErrorDialog: boolean;
	openSnackbar: boolean;
	expanded: boolean;
};

class SupportUserTableAccess extends Component<Props, State> {

	public static defaultProps = {
		tableRead: null,
		tableWrite: null,
		userId: "",
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: true,
			allTables: [],
			tables: [],
			buttonDisabled: true,
			openErrorDialog: false,
			openSnackbar: false,
			expanded: false,
		};

		this.handleCheckboxToggled = this.handleCheckboxToggled.bind(this);
		this.handleToggleExpandedClick = this.handleToggleExpandedClick.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
		this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
	}

	override componentDidMount() {
		this.fetchTableNames();
	}

	override componentDidUpdate(prevProps) {
		if (this.props.userId !== prevProps.userId || this.props.tableRead !== prevProps.tableRead || this.props.tableWrite !== prevProps.tableWrite) {
			this.setState((prevState) => ({
				tables: SupportUserTableAccess.prepareTables(prevState.allTables, this.props.tableRead, this.props.tableWrite),
				buttonDisabled: true,
				openErrorDialog: false,
				openSnackbar: false,
			}));
		}
	}

	static prepareTables(allTables, tableRead, tableWrite) {
		return allTables.map((tableName) => ({
			name: tableName,
			visible: User.user?.table_read?.includes(tableName) ?? false,
			editable: User.hasLevel("sup_user_write"),
			read: tableRead.includes(tableName),
			write: tableWrite.includes(tableName),
		}));
	}

	fetchTableNames() {
		const cmd = {
			action: "getTableNames",
		} as const satisfies CmdGetTableNames;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				const allTables = msg.payload.data.map((table) => (table.id)).sort(sortAlphabetically);

				this.setState({
					loading: false,
					allTables: allTables,
					tables: SupportUserTableAccess.prepareTables(allTables, this.props.tableRead, this.props.tableWrite),
				});
			} else {
				this.setState({
					loading: false,
					// error: error || msg.payload.data
				});
			}
		});
	}

	handleCheckboxToggled(value, type, oldRead, oldWrite, event, isInputChecked) {
		this.setState((prevState) => ({
			tables: prevState.tables.map((table) => (
				(value === table.name) ? merge(table, {
					read: (type === "read") ? isInputChecked : (oldWrite) ? table.read : true,
					write: (type === "write") ? isInputChecked : (oldRead) ? false : table.write,
				}) : table
			)),
			buttonDisabled: false,
		}));
	}

	handleToggleExpandedClick() {
		this.setState((prevState) => ({
			expanded: !prevState.expanded,
		}));
	}

	handleSaveClick() {
		if (Gupport.saveGlogin) {
			const cmd = {
				action: "saveGlogin",
				supUserId: this.props.userId,
				table_read: this.state.tables.filter((table) => (table.read)).map((table) => (table.name)),
				table_write: this.state.tables.filter((table) => (table.write)).map((table) => (table.name)),
			} as const satisfies CmdSaveGlogin;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.setState({
						buttonDisabled: true,
						openSnackbar: true,
					});
				} else {
					this.setState({
						openErrorDialog: true,
					});
				}
			});
		}
	}

	handleDialogClose() {
		this.setState({
			openErrorDialog: false,
		});
	}

	handleSnackbarClose() {
		this.setState({
			openSnackbar: false,
		});
	}

	override render() {
		const { t } = this.props;
		return (
			<>
				<Paper style={{ marginTop: "16px" }}>
					<ListItemButton style={{ paddingTop: "12px", paddingBottom: "12px" }} onClick={this.handleToggleExpandedClick}>
						<ListItemText primary={t("supportUser.tableAccess")} />
						<ListItemIcon className="ListItemIcon-right">
							{this.state.expanded ? <icons.ExpandLess /> : <Svg src="navigation/arrowDropDown.svg" />}
						</ListItemIcon>
					</ListItemButton>
					<Collapse in={this.state.expanded} timeout="auto">
						{this.state.loading ? <CircularProgress /> :
							<>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell style={{ width: "128px" }}>{t("supportUser.table")}</TableCell>
											<TableCell style={{ width: "72px" }}>{t("supportUser.read")}</TableCell>
											<TableCell style={{ width: "72px" }}>{t("supportUser.write")}</TableCell>
											<TableCell />
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.tables.filter((table) => (table.visible)).map((table) => (
											<TableRow key={table.name} className="last-row-no-border">
												<TableCell style={{ width: "128px" }}>
													{table.name}
												</TableCell>
												<TableCell style={{ width: "72px" }}>
													<Checkbox
														checked={table.read}
														disabled={!table.editable}
														onChange={this.handleCheckboxToggled.bind(this, table.name, "read", table.read, table.write)}
													/>
												</TableCell>
												<TableCell style={{ width: "72px" }}>
													<Checkbox
														checked={table.write}
														disabled={!table.editable}
														onChange={this.handleCheckboxToggled.bind(this, table.name, "write", table.read, table.write)}
													/>
												</TableCell>
												<TableCell />
											</TableRow>
										))}
									</TableBody>
								</Table>
								{User.hasLevel("sup_user_write") && Gupport.saveGlogin &&
									<Button
										variant="contained"
										disabled={this.state.buttonDisabled}
										style={{ margin: "16px 0 12px 24px" }}
										onClick={this.handleSaveClick}
									>
										{t("supportUser.save")}
									</Button>
								}
							</>
						}
					</Collapse>
				</Paper>
				<Dialog
					fullWidth={true}
					open={this.state.openErrorDialog}
					onClose={this.handleDialogClose}
				>
					<DialogTitle>{t("supportUser.saveErrorTitle")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t("supportUser.saveTables")} {this.props.userId} {t("supportUser.saveErrorMsg")}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleDialogClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
				<Snackbar
					open={this.state.openSnackbar}
					message={t("supportUser.changedSaved")}
					autoHideDuration={2000}
					onClose={this.handleSnackbarClose}
				/>
			</>
		);
	}

}

export default withTranslation()(SupportUserTableAccess);
