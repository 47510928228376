import { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { CircularProgress } from "@mui/material";
// cmp
import SupportUserInfo from "../cmp/support-user/SupportUserInfo";
// contexts
import { useUserData } from "../context/UserDataContext";
// services
import { Storage, StorageKeys } from "../services/storage";
// types
import type { UserId } from "../types/user";

type RouteParams = {
	userId?: UserId;
};

const SupportUsersPage = () => {
	const params = useParams<RouteParams>();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { ready } = useUserData();
	const selectedSupportUserId = params.userId ?? Storage.get(StorageKeys.supportUserId);

	useEffect(() => {
		if (selectedSupportUserId && pathname === "/support/users") {
			void navigate(`/support/users/${selectedSupportUserId}`);
		}
	}, [pathname, selectedSupportUserId, navigate]);

	if (!ready) {
		return <CircularProgress />;
	}
	if (selectedSupportUserId === null) {
		return null;
	}

	return <SupportUserInfo userId={selectedSupportUserId} onUserRemoved={() => (navigate("/support/users"))} />;
};

export default SupportUsersPage;
