import { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import {
	CircularProgress,
	Paper,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Divider,
} from "@mui/material";
import SupportUserTableAccess from "./SupportUserTableAccess";
import SupportUserLevels from "./SupportUserLevels";
import RowEntry from "../row-entry";
// services
import User from "../../services/user";
import Gupport from "../../services/gupport";
// types
import type { WithTranslation } from "react-i18next";
import type { UserId } from "../../types/user";
import type { CmdGetGlogin, CmdDeleteGlogin } from "../../types/gupport";

type Props = Readonly<WithTranslation & {
	userId: UserId;
	onUserRemoved: (userId: UserId) => void;
}>;

type State = {
	loading: boolean;
	user: any;
	openRemoveDialog: boolean;
	openErrorDialog: boolean;
};

class SupportUserInfo extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: true,
			user: null,
			openRemoveDialog: false,
			openErrorDialog: false,
		};

		this.showRemoveDialog = this.showRemoveDialog.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
		this.handleRemoveUserClick = this.handleRemoveUserClick.bind(this);
	}

	override componentDidMount() {
		this.fetchGlogin(this.props.userId);
	}

	override componentDidUpdate(prevProps) {
		if (this.props.userId !== prevProps.userId) {
			this.fetchGlogin(this.props.userId);
		}
	}

	fetchGlogin(userId) {
		this.setState({
			loading: true,
		});

		if (Gupport.getGlogin) {
			const cmd = {
				action: "getGlogin",
				supUserId: userId,
			} as const satisfies CmdGetGlogin;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.setState({
						loading: false,
						user: msg.payload.data,
					});
				}
			});
		}
	}

	showRemoveDialog() {
		this.setState({
			openRemoveDialog: true,
			openErrorDialog: false,
		});
	}

	handleDialogClose() {
		this.setState({
			openRemoveDialog: false,
			openErrorDialog: false,
		});
	}

	handleRemoveUserClick() {
		if (Gupport.deleteGlogin) {
			const cmd = {
				action: "deleteGlogin",
				supUserId: this.props.userId,
			} as const satisfies CmdDeleteGlogin;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.setState({
						openRemoveDialog: false,
						openErrorDialog: false,
					});

					this.props.onUserRemoved(this.props.userId);
				} else {
					this.setState({
						openRemoveDialog: false,
						openErrorDialog: true,
					});
				}
			});
		}
	}

	override render() {
		const { t } = this.props;
		if (this.state.loading) {
			return <CircularProgress />;
		}

		const date = new Date(this.state.user.created / 1000);

		return (
			<>
				<Paper className="card-separator card-info">
					<RowEntry title={t("supportUser.id")}>
						{this.state.user.user_id}
					</RowEntry>
					<Divider />
					<RowEntry title={t("supportUser.username")}>
						{this.state.user.user_name}
					</RowEntry>
					<Divider />
					<RowEntry title={t("supportUser.name")}>
						{this.state.user.display_name}
					</RowEntry>
					<Divider />
					<RowEntry title={t("supportUser.channel")}>
						{this.state.user.channel}
					</RowEntry>
					<Divider />
					<RowEntry title={t("supportUser.created")}>
						<time dateTime={date.toISOString()}>{date.toLocaleString()}</time>
					</RowEntry>
				</Paper>
				<SupportUserTableAccess userId={this.props.userId} tableRead={this.state.user.table_read} tableWrite={this.state.user.table_write} />
				<SupportUserLevels userId={this.props.userId} userLevels={this.state.user.level} />
				{User.hasLevel("sup_user_write") && Gupport.deleteGlogin &&
					<Button variant="contained" style={{ margin: "16px 0" }} onClick={this.showRemoveDialog}>
						{t("supportUser.removeUser")}
					</Button>
				}
				<Dialog
					fullWidth={true}
					open={this.state.openRemoveDialog}
					onClose={this.handleDialogClose}
				>
					<DialogTitle>{t("supportUser.dialogTitle")}</DialogTitle>
					<DialogContent>
						<Trans
							i18nKey="supportUser.dialogMsg"
							values={{ displayName: this.state.user.display_name, userName: this.state.user.user_name, userId: this.props.userId }}
						/>
					</DialogContent>
					<DialogActions>
						<Button color="danger" onClick={this.handleRemoveUserClick}>{t("supportUser.remove")}</Button>
						<Button color="inherit" onClick={this.handleDialogClose}>{t("dialog.cancel")}</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					open={this.state.openErrorDialog}
					onClose={this.handleDialogClose}
				>
					<DialogTitle>{t("supportUser.dialogTitle")}</DialogTitle>
					<DialogContent>
						{this.props.userId} {t("supportUser.errorMsg")}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleDialogClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}

}

export default withTranslation()(SupportUserInfo);
