import { Component } from "react";
import { withTranslation } from "react-i18next";
import {
	Paper,
	CircularProgress,
	Checkbox,
	Snackbar,
	Collapse,
	ListItemButton,
	ListItemText,
	ListItemIcon,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";
import Svg from "../svg";
// services
import User from "../../services/user";
import Gupport from "../../services/gupport";
import { sortAlphabetically } from "../../services/l10n";
import { merge } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { WithTranslation } from "react-i18next";
import type { UserId } from "../../types/user";
import type { UserLevel } from "../../types/misc";
import type { Level, CmdGetTable, CmdSaveGlogin } from "../../types/gupport";

type Props = Readonly<WithTranslation & {
	userId: UserId;
	userLevels: UserLevel;
}>;

interface Levels {
	checked: boolean;
	editable: boolean;
	name: Level;
	visible: boolean;
}

type State = {
	loading: boolean;
	allLevels: Array<Level>;
	levels: Array<Levels>;
	buttonDisabled: boolean;
	openErrorDialog: boolean;
	openSnackbar: boolean;
	expanded: boolean;
};

class SupportUserLevels extends Component<Props, State> {

	public static defaultProps = {
		userId: "",
		userLevels: null,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: true,
			allLevels: [],
			levels: [],
			buttonDisabled: true,
			openErrorDialog: false,
			openSnackbar: false,
			expanded: false,
		};

		this.handleCheckboxToggled = this.handleCheckboxToggled.bind(this);
		this.handleToggleExpandedClick = this.handleToggleExpandedClick.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
		this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
	}

	override componentDidMount() {
		this.fetchUserLevels();
	}

	override componentDidUpdate(prevProps) {
		if (this.props.userId !== prevProps.userId || this.props.userLevels !== prevProps.userLevels) {
			this.setState((prevState) => ({
				levels: SupportUserLevels.prepareLevels(prevState.allLevels, this.props.userLevels),
				buttonDisabled: true,
				openErrorDialog: false,
				openSnackbar: false,
			}));
		}
	}

	static prepareLevels(allLevels, userLevels) {
		return allLevels.map((levelName) => ({
			name: levelName,
			visible: User.hasLevel(levelName),
			editable: User.hasLevel("sup_user_write"),
			checked: userLevels.includes(levelName),
		}));
	}

	fetchUserLevels() {
		const cmd = {
			action: "getTable",
			tableName: "api_schemas",
		} as const satisfies CmdGetTable;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				const allLevels = msg.payload.data
					.map((level) => (level.data.rbac))
					.filter((levelName, index, array) => (levelName !== undefined && array.indexOf(levelName) === index))
					.sort(sortAlphabetically);

				this.setState({
					loading: false,
					allLevels: allLevels,
					levels: SupportUserLevels.prepareLevels(allLevels, this.props.userLevels),
				});
			} else {
				this.setState({
					loading: false,
					// error: error || msg.payload.data
				});
			}
		});
	}

	handleCheckboxToggled(value/*, event, isInputChecked*/) {
		this.setState((prevState) => ({
			levels: prevState.levels.map((level) => (merge(level, {
				checked: (value === level.name) ? !level.checked : level.checked,
			}))),
			buttonDisabled: false,
		}));
	}

	handleToggleExpandedClick() {
		this.setState((prevState) => ({
			expanded: !prevState.expanded,
		}));
	}

	handleSaveClick() {
		if (Gupport.saveGlogin) {
			const cmd = {
				action: "saveGlogin",
				supUserId: this.props.userId,
				level: this.state.levels.filter((level) => (level.checked)).map((level) => (level.name)),
			} as const satisfies CmdSaveGlogin;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.setState({
						buttonDisabled: true,
						openSnackbar: true,
					});
				} else {
					this.setState({
						openErrorDialog: true,
					});
				}
			});
		}
	}

	handleDialogClose() {
		this.setState({
			openErrorDialog: false,
		});
	}

	handleSnackbarClose() {
		this.setState({
			openSnackbar: false,
		});
	}

	override render() {
		const { t } = this.props;

		return (
			<>
				<Paper style={{ marginTop: "16px" }}>
					<ListItemButton style={{ paddingTop: "12px", paddingBottom: "12px" }} onClick={this.handleToggleExpandedClick}>
						<ListItemText primary={t("supportUser.levels")} />
						<ListItemIcon className="ListItemIcon-right">
							{this.state.expanded ? <icons.ExpandLess /> : <Svg src="navigation/arrowDropDown.svg" />}
						</ListItemIcon>
					</ListItemButton>
					<Collapse in={this.state.expanded} timeout="auto">
						{this.state.loading ? <CircularProgress /> :
							<>
								<Table>
									<TableBody>
										{this.state.levels.filter((level) => (level.visible)).map((level) => (
											<TableRow key={level.name} className="last-row-no-border">
												<TableCell style={{ width: "128px" }}>
													{level.name}
												</TableCell>
												<TableCell style={{ width: "72px" }}>
													<Checkbox
														checked={level.checked}
														disabled={!level.editable}
														onChange={this.handleCheckboxToggled.bind(this, level.name)}
													/>
												</TableCell>
												<TableCell />
											</TableRow>
										))}
									</TableBody>
								</Table>
								{User.hasLevel("sup_user_write") && Gupport.saveGlogin &&
									<Button
										variant="contained"
										disabled={this.state.buttonDisabled}
										style={{ margin: "16px 0 12px 24px" }}
										onClick={this.handleSaveClick}
									>
										{t("supportUser.save")}
									</Button>
								}
							</>
						}
					</Collapse>
				</Paper>
				<Dialog
					fullWidth={true}
					open={this.state.openErrorDialog}
					onClose={this.handleDialogClose}
				>
					<DialogTitle>{t("supportUser.saveErrorTitle")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t("supportUser.saveLevels")} {this.props.userId} {t("supportUser.saveErrorMsg")}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleDialogClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
				<Snackbar
					open={this.state.openSnackbar}
					message={t("supportUser.changedSaved")}
					autoHideDuration={2000}
					onClose={this.handleSnackbarClose}
				/>
			</>
		);
	}

}

export default withTranslation()(SupportUserLevels);
